import "bootstrap/dist/css/bootstrap.min.css";
import "vue-multiselect/dist/vue-multiselect.css";
import "bootstrap";
import "@/scss/style.scss";

import {createApp} from "vue";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import moment from "moment";
import VueHtmlToPaper from "@/plugins/VueHtmlToPaper";
import {createI18n} from "vue-i18n";

import {languages} from "@/locales/index.js";
import {defaultLocale} from "@/locales/index.js";

/* import the fontawesome core */
import {library} from "@fortawesome/fontawesome-svg-core";
/* import font awesome icon component */
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
/* import specific icons */
import {
    faUserPen,
    faQrcode,
    faPenToSquare,
    faGear,
    faKey,
    faUserGroup,
    faRightFromBracket,
    faRightToBracket,
    faUser,
    faEarthEurope,
    faWarehouse,
    faDolly,
    faCubes,
    faArrowDownWideShort,
    faArrowUpWideShort,
    faXmark,
    faClockRotateLeft,
    faCircleInfo, faEllipsis
} from "@fortawesome/free-solid-svg-icons";

/* add icons to the library */
library.add(faUserPen);
library.add(faQrcode);
library.add(faPenToSquare);
library.add(faGear);
library.add(faKey);
library.add(faUserGroup);
library.add(faRightFromBracket);
library.add(faRightToBracket);
library.add(faUser);
library.add(faEarthEurope);
library.add(faWarehouse);
library.add(faDolly);
library.add(faCubes);
library.add(faArrowDownWideShort);
library.add(faArrowUpWideShort);
library.add(faXmark);
library.add(faClockRotateLeft);
library.add(faCircleInfo);
library.add(faEllipsis);

const messages = Object.assign(languages);

const i18n = createI18n({
    // something vue-i18n options here ...
    locale: defaultLocale,
    fallbackLocale: "de",
    messages,
});

const app = createApp(App);
/**
 * @params {date} date to be formatted
 * @returns returns formatted date
 */
app.config.globalProperties.$filters = {
    formatDate(date) {
        return moment(date).format("DD.MM.YY HH:mm");
    },
};
app.config.errorHandler = (err, instance, info) => {
// Handle the error globally
    console.error("Global error:", err);
    console.log("Vue instance:", instance);
    console.log("Error info:", info);

};

app.use(store);
//app.config.devtools = true;
app.use(router);
app.use(VueAxios, axios);
app.use(VueHtmlToPaper);
app.use(i18n);
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");

import store from "./store";
import router from "./router";
